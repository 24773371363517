<template>
    <header>
        <div class="headerLogo">
            <img src="logo.svg" />
        </div>
        <nav>
            <div class="navlink">
                <a class="active" href="/">
                    Home
                </a>
            </div>
            <div class="navlink">
                <a href="#about">
                    About
                </a>
            </div>
            <div class="navlink">
                <darkmode-icon v-model="isDark" @change="toggleDarkTheme"/>
            </div>
            <select @change="selectLocale" ref="localeSetter">
                <option value="en">English</option>
                <option value="nl">Nederlands</option>
            </select>
        </nav>
    </header>
</template>

<script>
    import darkmodeIcon from "./darkmodeIcon";

    export default {
        name: 'navigation',
        data() {
            return {
                isDark: document.getElementById("html").getAttribute("theme") === "dark",
                currentTheme: document.getElementById("html").getAttribute("theme")
            };
        },
        components: {
            darkmodeIcon
        },
        mounted() {
            this.$refs.localeSetter.value = window.localStorage.getItem("lang") ?? "en";
        },
        methods: {
            toggleDarkTheme() {
                if (this.isDark) {
                    document.getElementById("html").setAttribute("theme", "dark");
                } else {
                    document.getElementById("html").setAttribute("theme", "");
                }
                let currentTheme = document.getElementById("html").getAttribute("theme");
                window.localStorage.setItem("theme", currentTheme);
            },
            selectLocale(event) {
                if(document.getElementById("html").getAttribute("lang") !== event.target.value) {
                    document.getElementById("html").setAttribute("lang", event.target.value);
                    window.localStorage.setItem("lang", event.target.value);
                    window.location.reload();
                }
            }
        }
    }
</script>

<style scoped>
    header {
        background-color: var(--background-primary);
        height: 70px;
        align-items: center;
        display: flex;
        padding-left: 20px;
    }
    nav {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 25px;
    }
    .navlink {
        display: inline-block;
        padding: 10px;
    }
    div.navlink a:hover {
        color: var(--text-secondary);
        transition: color .5s
    }
    div.navlink a {
        display: inline;

        color: var(--text-navigation);
        font-size: 22px;
        text-decoration: none;
        font-weight: bolder;
    }


    .headerLogo {
        display: inline-block;
        margin-right: 0px;
        height: 75%;
    }
    img {
        height: 100%;
        filter: var(--filter-text-secondary)
        /*https://codepen.io/sosuke/pen/Pjoqqp*/
    }

    @media screen and (max-width: 600px) {
      header { flex-direction: column; height: fit-content}
      .headerLogo {
          display: none;
      }
      nav {
          display: flex;
          flex-direction: column;
          margin: auto;
          text-align: center;
      }
    }
</style>
