<template>
  <clean v-if="$route.meta.template == 'clean'" />
  <defaultView v-else />
</template>

<script>
    import Routes from "./scripts/router";
    import clean from "./templates/clean";
    import defaultView from "./templates/default";

    export default {
        name: "app",
        router: Routes.router,
        components: {
            clean,
            defaultView
        },
        created() {
            document.getElementById("html").setAttribute("theme", window.localStorage.getItem("theme"));
            document.getElementById("html").setAttribute("lang", window.localStorage.getItem("lang"));
        }
    };
</script>

<style>
    :root {
        --background-primary: #fdfdfd;
        --background-secondary: #ab00ab;

        --primary: magenta;

        --filter-secondary: invert(86%) sepia(100%) saturate(4143%) hue-rotate(293deg) brightness(81%) contrast(116%);
        --text-primary: black;
        --text-secondary: var(--primary);
        --text-navigation: grey;
        --filter-text-secondary: invert(79%) sepia(89%) saturate(4890%) hue-rotate(295deg) brightness(114%) contrast(126%);

        --logo-color: white;

        font-family: sans-serif;
    }

    #darkmode-switch-inner-circle {
        background-color: green;
        color:green;
    }

    * {
        transition: background-color 1s;
    }

    [theme="dark"]{
        --background-primary: #333335;
        /* --background-secondary: #4a4848; */
        --text-primary: white;
    }

    body {
        margin: 0;
        background-color: var(--background-primary);
        color: var(--text-primary);
    }

    .v-center {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .clickable {
        cursor: pointer;
    }
</style>
