<template>
    <div class="carousel-wrapper" :class="{ bigger: showControls }">
        <carousel :item-count="2" :show-controls="showControls">
            <template #1><line-chart /></template>
            <template #0><horizon-chart /></template>
        </carousel>
    </div>
</template>

<script>
    import carousel from "@/components/carousel.vue";
    import lineChart from "./horizonChart.vue";
    import horizonChart from "./lineChart.vue";

    export default {
        name: "chartCarousel",
        components: {
            carousel,
            lineChart,
            horizonChart
        },
        data() {
            return {
                showControls: false
            };
        },
        mounted() {
            this.$watch(
                () => this.$parent.isOpen,
                itemFullscreened => (this.showControls = itemFullscreened)
            );
        }
    };
</script>

<style scoped>
    .carousel-wrapper {
        height: 100%;
        width: 85%;
        position: absolute;
    }

    .carousel-wrapper.bigger {
        width: calc(100% - 2.25em);
    }
</style>
