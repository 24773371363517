<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" @click="toggle" class="clickable">
        <title>darkmode</title>
        <clipPath id="toggle-mask">
            <circle cx="499" cy="499" r="304" />
        </clipPath>
        <g clip-path="url(#toggle-mask)">
            <circle ref="inner-circle" cx="499" cy="499" r="248" fill="var(--text-primary)" />
        </g>
        <path
            :style="modelValue ? 'transform:rotate(-45deg)' : ''"
            fill="var(--text-primary)"
            d="M992.4,413.18,782.63,336.82,821.39,117,611.62,193.33,500,0,388.38,193.33,178.61,117l38.76,219.84L7.6,413.18l171,143.49L67,750H290.23L329,969.85l171-143.5,171,143.5L709.77,750H933L821.39,556.67ZM499.5,790C339.06,790,209,659.94,209,499.5S339.06,209,499.5,209,790,339.06,790,499.5,659.94,790,499.5,790Z"
        />
    </svg>

</template>

<script>

export default {
    name: "darkmodeIcon",
    props: ["modelValue"],
    emits: ["update:modelValue", "change"],
    mounted() {
        if (this.modelValue) {
            this.$refs["inner-circle"].style.transform = "translateX(-25%)";
        }
    },
    methods: {
        toggle() {
            let goesDark = !this.modelValue;
            if (goesDark) {
                this.$refs["inner-circle"].style.transform = "translateX(-25%)";
            } else {
                this.$refs["inner-circle"].style.transform = "translateX(0)";
            }
            this.$emit("update:modelValue", goesDark);
            this.$emit("change");
        }
    }
}
</script>

<style scoped>
    circle, path {
        transform-origin: center center;
        transition: all 1s;
    }

    svg {
        width: 2em;
    }
</style>
