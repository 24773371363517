<template>
    <div class="carousel">
        <a @click="moveDirection(-1)" class="clickable carousel-nav" title="Previous item" v-show="showControls ?? true">&lt;</a>
        <a @click="moveDirection(1)" class="clickable carousel-nav right" title="Next item" v-show="showControls ?? true">&gt;</a>
        <div
            v-for="idx in itemCount"
            :key="idx"
            class="carousel-item"
            :prev="idx - 1 == newSlide(-movedDirection) && movedDirection !== 0"
            :active="activeIndex == idx - 1"
        >
            <slot :name="idx - 1" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "carousel",
        data() {
            return {
                activeIndex: 0,
                movedDirection: 0
            };
        },
        props: ["item-count", "show-controls"],
        methods: {
            newSlide(direction) {
                let activeIndex = this.activeIndex;
                activeIndex += direction;
                activeIndex %= this.itemCount;
                if (activeIndex < 0) {
                    activeIndex = this.itemCount - 1;
                }

                return activeIndex;
            },
            moveDirection(direction) {
                this.movedDirection = direction;
                this.activeIndex = this.newSlide(direction);
            }
        }
    };
</script>

<style>
    @keyframes appear {
        to {
            opacity: 1;
            visibility: unset;
        }
        from {
            opacity: 0;
            visibility: hidden;
        }
    }

    /* This is needed, because otherwise when reversing the animation it will already have finished (as it is already at the final frame) */
    @keyframes fade {
        from {
            opacity: 1;
            visibility: unset;
        }
        to {
            opacity: 0;
            visibility: hidden;
        }
    }
</style>
<style scoped>
    .carousel {
        position: relative;
        margin: 0 1em;
        width: 100%;
        height: 100%;
    }

    .carousel-nav {
        position: absolute;
        display: inline;
        z-index: 10;
        color: white;
        background-color: #222;
        padding: 1em 1.25em;
        border-radius: 100%;
        top: 50%;
        transform: translateY(-25%);
        margin-top: -0.5em;
        left: -1em;
    }

    .carousel-nav.right {
        left: unset;
        right: -1em;
    }

    .carousel-item {
        transition: all 1s;
        position: absolute;
        height: 100%;
        width: 100%;
        visibility: hidden;
        overflow: hidden;
    }

    .carousel-item[active="true"] {
        animation: appear 0.5s both;
    }

    .carousel-item[prev="true"] {
        animation: fade 0.5s both;
    }
</style>
