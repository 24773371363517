<template>
    <div ref="progressbar-wrapper">
        <div class="bar" v-bind:style="{width: actualProgress+'%'}">
        </div>
    </div>
</template>

<script>
    export default{
        name: "progressbar",
        props: ['progress'],
        data() {
            return {
                actualProgress: 0
            }
        },
        methods: {
            observerFired(entries) {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.actualProgress = this.progress
                    }
                });
            }
        },
        mounted() {
            let options = {
                treshold: 0.3
            }

            let observer = new IntersectionObserver(this.observerFired, options);
            observer.observe(this.$refs["progressbar-wrapper"]);
        }
    }
</script>

<style scoped>
    div {
        width: 100%;
        height: 2em;
        background-color: lightgrey;
        border-radius: 4px;
        overflow: hidden;
    }
    div.bar {
        background-color: var(--background-secondary);
        transition: width 1s;
        border-radius: 0;
    }
</style>
