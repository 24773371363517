<template>
    <navigation />
    <div id="page">
    <router-view @pageTitle="pageTitleChanged" id="content" />
</div>
</template>

<script>
import navigation from "@/components/navigation";

export default {
    components: {
          navigation
    },
    methods: {
        pageTitleChanged(title) {
            this.title = title;
        }
    }
};
</script>

<style scoped>
    #content {
        margin-left: 15px;
        padding: 0 16px;
    }
</style>
