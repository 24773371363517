<template>
    <h2>{{ chartLocale.horizonChart }}</h2>
    <span>{{ chartLocale.numBands }} {{ numBands }}</span>
    <input type="range" min="1" max="9" v-model="numBands" @input="() => createChart()" />
    <div v-html="svg" id="chart"></div>
</template>

<script>
    import * as d3 from "d3";
    import { chartLocale } from "@/scripts/locale.js";

    export default {
        name: "chart",
        data() {
            return {
                svg: "",
                numBands: 5
            };
        },
        computed: {
            chartLocale() {
                return chartLocale;
            }
        },
        methods: {
            async createChart() {
                const numBands = parseInt(this.numBands);

                const size = {
                    width: 2400,
                    height: 1080
                };

                const bandHeight = size.height;

                let data = [];
                const dataPoints = 2000;
                for (var i = 0; i < dataPoints; i++) {
                    data.push(Math.sin(i / 400));
                }
                //let extent = d3.extent(data);
                let xScale = d3
                    .scaleLinear()
                    .domain([0, dataPoints])
                    .range([0, size.width]);
                let yScale = d3
                    .scaleLinear()
                    .domain([0, 1])
                    .range([0, -numBands * bandHeight]);

                const positiveAreaGenerator = d3
                    .area()
                    .curve(d3.curveBasis)
                    .x((d, i) => xScale(i))
                    .y0(0)
                    .y1(d => yScale(d));
                const negativeAreaGenerator = d3
                    .area()
                    .curve(d3.curveBasis)
                    .x((d, i) => xScale(i))
                    .y0(0)
                    .y1(d => yScale(-d));

                const positiveColour = band => d3.schemePurples[Math.max(3, numBands)][band + Math.max(0, 3 - numBands)];
                const negativeColour = band => d3.schemeGreens[Math.max(3, numBands)][band + Math.max(0, 3 - numBands)];

                const svg = d3
                    .create("svg")
                    .attr("viewBox", [0, 0, size.width, size.height])
                    .attr("class", "project-preview");

                let g = svg.append("g").attr("transform", `translate(0, ${bandHeight + 1})`);

                g.append("clipPath")
                    .attr("id", "clipping-mask")
                    .append("rect")
                    .attr("width", size.width)
                    .attr("height", size.height);

                g.append("defs")
                    .append("path")
                    .attr("id", "pos-path-prefab")
                    .attr("d", positiveAreaGenerator(data));

                g.append("defs")
                    .append("path")
                    .attr("id", "neg-path-prefab")
                    .attr("d", negativeAreaGenerator(data));

                g.append("g")
                    .attr("clip-path", "#clipping-mask")
                    .selectAll("use")
                    .data(d => new Array(numBands).fill(d))
                    .join("use")
                    .attr("fill", (data, band) => positiveColour(band))
                    .attr("transform", (data, index) => `translate(0,${index * bandHeight})`)
                    .attr("xlink:href", "#pos-path-prefab");

                g.append("g")
                    .attr("clip-path", "#clipping-mask")
                    .selectAll("use")
                    .data(d => new Array(numBands).fill(d))
                    .join("use")
                    .attr("fill", (data, band) => negativeColour(band))
                    .attr("transform", (data, index) => `translate(0,${index * bandHeight})`)
                    .attr("xlink:href", "#neg-path-prefab");

                this.svg = svg.node().outerHTML;
            }
        },
        mounted() {
            this.createChart();
        }
    };
</script>

<style scoped>
    input,
    span,
    h2 {
        display: none;
    }

    >>> svg {
        height: 100%;
    }

    .open input,
    .open span,
    .open h2 {
        display: block;
    }

    #chart {
        height: 100%;
    }

    .open #chart {
        height: unset;
    }
</style>
