<template>
    <div class="projectbox closed" @click="projectBoxClicked">
        <div class="project-image">
            <div class="v-center">
                <slot>
                    <img v-bind:src="previewimage" class="project-preview" />
                </slot>
            </div>
        </div>
        <div class="project-description">
            <h2 class="project-title">{{ label }}</h2>
            <h2 class="project-title fullscreen-close-button clickable">x</h2>
            <p>{{ description }}</p>
        </div>
        <div class="projectbox-titlebar">
            <h2 class="project-title">{{ label }}</h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: "portfolioItem",
        props: ["label", "description", "previewimage"],
        data() {
            return {
                clone: undefined,
                originalElement: undefined,
                isOpen: false
            };
        },
        methods: {
            projectBoxClicked(event) {
                this.openFullscreen(event.target);
            },
            openFullscreen(element) {
                if (element == null || element.classList.contains("closeFullscreen")) return;
                if (
                    element.classList.contains("projectbox") &&
                    !element.classList.contains("open") &&
                    !element.classList.contains("opening") &&
                    !element.classList.contains("closing")
                ) {
                    let startLocation = {
                        top: element.offsetTop,
                        left: element.offsetLeft,
                        width: element.offsetWidth,
                        height: element.offsetHeight
                    };

                    let clone = element.cloneNode(true);
                    element.after(clone);

                    clone.classList.add("placeholder");
                    clone.style.opacity = 0;
                    element.classList.remove("closed");
                    element.classList.add("opening");
                    element.style.position = "absolute";
                    element.style.top = startLocation.top + "px";
                    element.style.left = startLocation.left + "px";
                    element.style.margin = "0";
                    element.getElementsByClassName("fullscreen-close-button")[0].addEventListener("click", this.closeFullscreen);

                    setTimeout(() => {
                        if (element.classList.contains("closing")) return;
                        element.classList.remove("opening");
                        element.classList.add("open");
                        this.isOpen = true;
                    }, 900);

                    this.originalElement = element;
                    this.clone = clone;

                    // This code 'disables' scrolling
                    var x = window.scrollX;
                    var y = window.scrollY;
                    window.onscroll = function() {
                        window.scrollTo(x, y);
                    };

                    setTimeout(() => {
                        element.style.width = "100%";
                        element.style.height = "100vh";
                        element.style.top = window.scrollY + "px";
                        element.style.left = "0px";
                    }, 100);
                } else {
                    this.openFullscreen(element.parentElement);
                }
            },
            closeFullscreen(event) {
                event.preventDefault();
                var startLocation = {
                    top: this.clone.offsetTop,
                    left: this.clone.offsetLeft,
                    width: this.clone.offsetWidth,
                    height: this.clone.offsetHeight
                };
                this.originalElement.style.top = startLocation.top + "px";
                this.originalElement.style.left = startLocation.left + "px";
                this.originalElement.style.width = startLocation.width + "px";
                this.originalElement.style.height = startLocation.height - 1 + "px"; //For some reason this needs a -1
                this.originalElement.style.margin = "auto";

                this.originalElement.classList.remove("open");
                this.originalElement.classList.add("closing");

                // Re-enable scroll
                window.onscroll = function() {};
                setTimeout(() => {
                    this.originalElement.style = "";
                    this.originalElement.classList.remove("closing");
                    this.originalElement.classList.add("closed");
                    this.clone.remove();
                    this.isOpen = false;
                }, 1500);
            }
        }
    };
</script>

<style scoped>
    .projectbox {
        width: 35vh;
        display: inline-block;
        margin: 1vw;
        min-width: 200px;
        height: 20vh;
        min-height: 150px;
        background-color: var(--background-primary);

        border-radius: 0.25vw;
        transition: all 1s, transform 0.3s;

        overflow: hidden;
    }

    .projectbox.open,
    .projectbox.closing,
    .projectbox.opening {
        z-index: 10;
    }

    .projectbox:not(.open) {
        cursor: pointer;
    }

    .projectbox:hover:not(.open):not(.opening) {
        transform: scale(1.05);
    }

    .v-center {
        width: 100%;
        height: 100%;
    }

    .open .v-center {
        height: 80%;
    }
    /*div.projectbox.fullscreen{
                            margin: 1vw;
                            width: 99%;
                            height: 100vh;
                            border: none;
                        }*/

    .open img.project-preview,
    .opening img.project-preview {
        max-width: 100%;
        max-height: 100%;
        height: unset;
    }

    .project-preview,
    :deep(.project-preview) {
        height: 100%;
    }

    .projectbox-titlebar {
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        color: white;
        padding: 0.2em;
        position: relative;
        transition: transform 0.3s;
    }

    .projectbox.closed .projectbox-titlebar {
        transform: translateY(-100%);
    }

    @media (hover) {
        .projectbox:not(:hover) .projectbox-titlebar {
            transform: unset;
        }
    }

    h2.project-title {
        margin-left: 1rem;
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
    }

    .fullscreen-close-button {
        position: absolute;
        opacity: 0;
        right: 1rem;
        transition: all 0.5s;
    }

    .open .fullscreen-close-button {
        opacity: 1;
    }

    div.project-image {
        width: 100%;
        transition: all 0.5s;
        display: inline-block;
        position: relative;
        height: 100%;
    }

    div.project-description {
        width: 23%;
        transition: all 0.5s;
        padding: 1%;
        display: none;
        position: absolute;
        height: 95%;
        overflow-y: auto;
    }

    div.project-description p {
        white-space: pre-line;
    }

    .open div.project-image {
        width: 75%;
    }

    .open div.project-description {
        display: inline-block;
    }

    @media screen and (max-width: 600px) {
        div.projectbox {
            width: 100%;
        }
        .project-preview,
        :deep(.project-preview) {
            height: unset;
            width: 100%;
        }
    }
</style>
