<template>
    <div class="skillbox">
        <h3>{{ label }}</h3>
        <progressbar :progress="progress" />
        <p>{{ description }}</p>
    </div>
</template>

<script>
import progressbar from '@/components/progressbar';
    export default {
        name: "skillbox",
        props: [ 'progress', 'label', 'description' ],
        components: {
            progressbar
        }
    }
</script>

<style scoped>
    div.skillbox {
        width: 15%;
        display: inline-block;
        margin: 1vw;
        min-width: 200px;
    }
    @media screen and (max-width: 600px) {
      div.skillbox{
          width: 100%;
      }
    }
</style>
