<template>
    <h2>{{ chartLocale.lineChart }}</h2>
    <div id="chart">
        <svg v-html="svg" :viewBox="[0, 0, size.width, size.height]" class="project-preview"></svg>
    </div>
</template>

<script>
    import * as d3 from "d3";
    import { chartLocale } from "@/scripts/locale.js";

    const size = {
        width: 2400,
        height: 1080
    };
    const dataPoints = 500;
    const aheadFactor = Math.PI * 50;

    const lineGenerator = d3
        .line()
        .curve(d3.curveBasis)
        .x((d, i) => xScale(i))
        .y(d => yScale(d));

    const xScale = d3
        .scaleLinear()
        .domain([0, dataPoints])
        .range([0, size.width]);

    const yScale = d3
        .scaleLinear()
        .domain([-1, 1])
        .range([16, size.height - 16]); // -2 em, which is the stroke width

    export default {
        name: "linechart",
        data() {
            return {
                svg: "",
                numBands: 5,
                dataOffset: 0,
                size
            };
        },
        computed: {
            chartLocale() {
                return chartLocale;
            }
        },
        methods: {
            async createChart() {
                let data = [];
                let data2 = [];
                for (var i = 0; i < dataPoints; i++) {
                    data.push(Math.sin((i + this.dataOffset) / 50));
                    data2.push(Math.sin((i + this.dataOffset + aheadFactor) / 50));
                }
                this.dataOffset += 1.5;

                const svg = d3.create("g");

                svg.append("path")
                    .attr("fill", "none")
                    .attr("stroke-width", "2em")
                    .attr("stroke", "green")
                    .attr("d", lineGenerator(data2));

                svg.append("path")
                    .attr("fill", "none")
                    .attr("stroke-width", "2em")
                    .attr("stroke", "var(--primary)")
                    .attr("d", lineGenerator(data));

                this.svg = svg.node().outerHTML;
                setTimeout(this.createChart, 20);
            }
        },
        mounted() {
            this.createChart();
        }
    };
</script>

<style scoped>
    h2 {
        display: none;
    }

    .open h2 {
        display: block;
    }

    #chart {
        height: 100%;
    }

    .open #chart {
        height: unset;
    }
</style>
