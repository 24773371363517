import { shallowRef } from "vue"

import chart from "@/components/chart/chartCarousel";

const _homepage = {
    programmer: { en: "grammer", nl: "grammeur" },
    skillsTitle: {
        en: "Skills",
        nl: "Vaardigheden"
    },
    skills: [
        {
            label: { en: "Prototypes" },
            progress: 100,
            description: {
                en: "I'm good at quickly setting up prototypes and proof of concepts.",
                nl: "Ik ben goed in het snel opzetten van prototypes en proof of concepts."
            }
        },
        {
            label: { en: ".Net" },
            progress: 100,
            description: {
                en: "Main framework used during the bachelors at Utrecht University. I have used this a lot in projects, and in creating apps for Galaxy Watch (Tizen.Net).",
                nl: "Meestgebruikte framework dat gebruikt wordt tijdens de bachelor aan de Universiteit Utrecht. Ik heb dit veel gebruikt in projecten, en bij het maken van apps voor Galaxy watch (Tizen.Net)."
            }
        },
        {
            label: { en: "JavaScript" },
            progress: 70,
            description: {
                en: "Used extensively in several web projects. Used on both the server-side, as well as the client-side.",
                nl: "Uitbundig gebruikt in verschillende webprojecten. Zowel aan de server-, als gebruikers-kant."
            }
        },
        {
            label: { en: "Containerised applications" },
            progress: 55,
            description: {
                en: "As more and more applications run on servers, containerisation is preferrable. Therefore I am actively trying to improve my containerisation skills by taking this into account when designing applications.",
                nl: "Omdat steeds meer applicaties op servers draaien, is containerisatie wenselijk. Daarom probeer ik mijn containerisatie-vaardigheden te verbeteren door hier rekening mee te houden bij het ontwerpen van applicaties."
            }
        }
    ],
    projectsTitle: {
        en: "Some Projects I Have Worked On",
        nl: "Enkele projecten waar ik aan heb gewerkt"
    },
    projects: [
        {
            label: { en: "D3 charts", nl: "D3 grafieken" },
            component: chart,
            description: {
                en: "For a Human Factors Research Tool, I have worked on an application that stores and visualises data, so that it can be analysed later on. I was mainly responsible for the visualising of this data in charts. Some examples of such charts can be found on the left. The data for these charts comes from a sine function. The charts are drawn using the D3 library.\n\n The first chart consists of two line charts, where one is half a period ahead of the other. \n The second chart is a horizon chart, that displays the positive and negative values in different colours.",
                nl: "Bij de ontwikkeling van een Human Factors Research Tool heb ik gewerkt aan een programma voor het opslaan en visualiseren van data, zodat het later geanalyseerd kan worden. Ik was verantwoordelijk voor het visualiseren van deze data in grafieken. Enkele voorbeelden van zulke grafieken zijn links te vinden. De data voor deze grafiek komt van een sinus-functie. De grafieken zijn getekend met behulp van de D3 bibliotheek.\n\n De eerste grafiek bestaat uit twee lijngrafieken, waarbij de ene een halve periode voor loopt op de andere.\n De tweede is een horizon chart, die de positieve en negatieve waarden in verschillende kleuren weergeeft."
            }
        },
        {
            label: { en: "Raytracer" },
            previewimage: "raytracer.png",
            description: {
                en: "For my bachelor's course in Graphics I made a very simple ray tracer in a team of three. This raytracer is made in C# and build on an openTK framework.\nI mainly worked on the shooting of rays from the screen and making the reflections work.\n\n\
                    The main features present in this raytracer were basic primitives, basic shading with colored textures, image textures, reflection (with a settable reflection index), shadows and the importing of .obj files. Many of these features can be seen in the render view on the left.",
                nl: "Voor mijn bachelorcursus Graphics heb ik in een team van drie een simpele raytracer gemaakt. Deze raytracer is gemaakt in C#, en is gebouwd op het openTK framework.\nIk heb vooral gewerkt op het afvuren van rays van het scherm, en het inwerken van de reflecties.\n\n\
                    De voornaamste functionaliteiten in deze raytracer zijn: basale primitieven, basale shading met gekleurde textures, foto's als textures, reflecties (met instelbare reflectie-index), schaduwen en het imporeren van .obj bestanden. Veel van deze features zijn de zien in de render aan de linkerzijde."
            }
        }
    ],
    introduction: {
        en: "Hi there! I'm Jaan, a passionate software developer and student from the Netherlands. I like to work on all sorts of projects.\n\
             Whether it's a website, a native application, an embedded system or a compiler, I enjoy solving the puzzles that a problem brings with it.\n\n\
             In my free time I work on smaller projects to keep up with the latest technologies, and practise my skills.",
        nl: "Hoihoi! Ik ben Jaan, een gepassioneerde software developer en student uit Nederland. Ik hou ervan om aan allerlei soorten projecten te werken.\n\
             Of het nu een website, een native application, een embedded system of een compiler is, ik vind het leuk om de puzzels op te lossen die een probleem met zich mee brengt.\n\n\
             In mijn vrije tijd werk ik aan kleine projecten om bij te blijven met de nieuwste technologiën."
    },
    quote: {
        en: "I love computers :D"
    }
}

const _chartPage = {
    numBands: { en: "Number of bands:", nl: "Aantal banden:" },
    horizonChart: { en: "Horizon chart", nl: "Horizongrafiek" },
    lineChart: { en: "Line chart", nl: "Lijngrafiek" },
}

function language() {
    let lang = document.getElementById("html").getAttribute("lang");
    if (lang == null) {
        lang = "en";
    }
    return lang;
}

function getLocalised(localeObject, key) {
    return localeObject[key][language()] ?? localeObject[key].en;
}

const homepageLocale = {
    get skillsTitle() {
        return getLocalised(_homepage, "skillsTitle");
    },
    get skills() {
        let skillList = []
        _homepage.skills.forEach(skill => {
            let tranlatedSkill = {
                label: getLocalised(skill, "label"),
                progress: skill.progress,
                description: getLocalised(skill, "description"),
            }
            skillList.push(tranlatedSkill);
        });
        return skillList;
    },
    get projectsTitle() {
        return getLocalised(_homepage, "projectsTitle");
    },
    get projects() {
        let projectList = []
        _homepage.projects.forEach(project => {
            let translatedProject = {
                label: getLocalised(project, "label"),
                previewimage: project.previewimage,
                description: getLocalised(project, "description"),
                component: shallowRef(project.component)
            }
            projectList.push(translatedProject);
        });
        return projectList;
    },
    get programmer() {
        return getLocalised(_homepage, "programmer");
    },
    get introduction() {
        return getLocalised(_homepage, "introduction");
    },
    get quote() {
        return getLocalised(_homepage, "quote");
    }
}

const chartLocale = {
    get numBands() {
        return getLocalised(_chartPage, "numBands");
    },
    get horizonChart() {
        return getLocalised(_chartPage, "horizonChart");
    },
    get lineChart() {
        return getLocalised(_chartPage, "lineChart");
    }
}

export { homepageLocale, chartLocale };
