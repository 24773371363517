<template>
    <div pageTitle="Welcome">
        <h1>
            <span style="color: var(--text-secondary)">Jaan</span> <br />
            <span style="opacity: 0">J</span><span style="color: var(--text-secondary)">Pro</span>{{ homepageLocale.programmer }}
        </h1>

        <p>{{ homepageLocale.introduction }}</p>

        <figure class="quote">
            <blockquote>
                <p>{{ homepageLocale.quote }}</p>
            </blockquote>
        </figure>

        <hr />

        <h2>{{ homepageLocale.skillsTitle }}</h2>
        <div class="skill-container">
            <skillbox v-for="skill in homepageLocale.skills" :key="skill" v-bind="skill" />
        </div>

        <hr />

        <h2>{{ homepageLocale.projectsTitle }}</h2>
        <div class="project-container">
            <portfolio-item v-for="project in homepageLocale.projects" :key="project" v-bind="project">
                <component v-if="project.component != undefined" :is="project.component" />
            </portfolio-item>
        </div>
    </div>
</template>

<script>
    import { homepageLocale } from "@/scripts/locale.js";

    import skillbox from "@/components/skillbox";
    import portfolioItem from "@/components/portfolioItem";
    import lineChart from "@/components/chart/lineChart";

    export default {
        name: "Home",
        components: {
            skillbox,
            portfolioItem,
            lineChart
        },
        data() {
            return {
                homepageLocale
            };
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .skill-container {
        display: flex;
        align-items: flex-start;
    }
    @media screen and (max-width: 600px) {
        div.skill-container {
            flex-wrap: wrap;
        }
    }

    hr {
        width: 25%;
        margin-left: 0;
    }
    p {
        white-space: pre-line;
    }
    .quote {
        margin-top: 2em;
        margin-bottom: 2em;
    }
    blockquote {
        font-size: 2em;
        margin: 0;
    }
    blockquote p::before {
        content: "\201C";
        font-size: 2em;
    }

    blockquote p::after {
        content: "\201E";
        font-size: 2em;
    }
</style>
