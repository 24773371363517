<template>
  <div id="page">
    <router-view/>
  </div>
</template>

<script>
export default {


}
</script>

<style scoped>

</style>