import { createWebHistory, createRouter } from "vue-router";

import Home from '../components/homepage';

const routes = [
    { path: "/:catchAll(.*)", component: Home } ,
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
